<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8" align-self="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="mr-1"
                color="grey darken-3"
                :to="{
                  name: page_route,
                }"
              >
                <v-icon v-text="'mdi-arrow-left'" />
              </v-btn>
            </template>
            <span v-text="'Atrás'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip v-if="login.permissions.faqs.update" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="ml-1"
                color="info"
                :to="{ name: `${page_route}.update`, params: { id: id } }"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span v-text="'Editar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'GENERAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Interfaz"
                    :value="data.faq_interface.faq_interface"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Titulo" :value="data.title" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Video (URL)" :value="data.url_video" />
                </v-col>
                <v-col cols="12">
                  <ViewData label="Comentario" :value="data.comment" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="data.documents && data.documents.length > 0" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'ARCHIVOS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(document, i) in data.documents"
                  :key="i"
                >
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <span
                        class="text-caption font-weight-bold"
                        v-text="`Descripción (${i + 1})`"
                      />
                      <br />
                      <span
                        class="text-description"
                        v-text="document.description"
                      />

                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="url_documents + '/faqs/' + document.url"
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="pb-1" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTRO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-tooltip v-if="login.permissions.faqs.delete" top>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="ml-1"
                :loading="loading"
                color="error"
                @click.prevent="HandleDesactivate"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span v-text="'Desactivar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  URL_DOCUMENTS,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    ViewData,
  },
  data() {
    return {
      page_route: "faqs",
      api: "faqs",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      rules: rules(),
      loading: false,
      data: null,
      url_documents: URL_DOCUMENTS,
    };
  },
  methods: {
    getData() {
      this.loading = true;

      Axios.get(
        `${URL_API}/${this.api}/${this.id}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.data = response.data.data;
        this.loading = false;
      });
    },
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            Axios.delete(
              `${URL_API}/${this.api}/${this.id}`,
              headersToken(this.login.token)
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              response.data.success
                ? this.$router.push({ name: this.page_route })
                : console.log(response.data.message);
            });
          }
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>